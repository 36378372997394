import { supabaseFetch, supabaseRealtimeInit } from "./realtime";
import { processOrders } from "./fetchOrders";

/* concepts:
 *
 * orderData {
 *      "url": {
 * 		    "date":
 * 	     	    [
 * 	     		    company: str,
 * 	    		    customerName: str,
 *   		       	customerNote: str,
 * 	       		    distance: str,
 * 	    	    	menus: {
 * 	    		    	"productShorthand":  { "sizes": qtys, ...},
 * 	    	    	},
 * 	    	    	orderNumber: str,
 * 	    	    	paymentMethod: str,
 * 		        	phone: str,
 * 		        	shippingAddress: str,
 * 		        	shippingMethod: str,
 * 		        	time: str,
 * 		        	total: str,
 * 	    	    ],
 * 		    "date": [...],
 *      },
 *      "url": {},
 *      ...
 * }
 *
 * productQuantities {
 *      "url": {
 *          "date": {
 *              "productShorthands": {
 * 			        orders: {
 * 			        	"orderIds": {"sizes": qtys, ...},
 * 			        },
 * 			        totalQtys: {"sizes": qtys, ...},
 * 		        }
 *          }
 *      },
 *      "url": {...}
 * }
 *
 * sortedProductQuantities {
 *      "url": {
 *          "date": [
 * 		        ["productShorthand", {
 * 			        orders: {
 * 			        	"orderIds": {"sizes": qtys, ...},
 * 			        },
 * 			        totalQtys: {"sizes": qtys, ...},
 * 		        }], ["", {}], ...
 *          ],
 *          ...
 *      },
 *      "url": {...}
 * ]
 *
 * productComponents {
 * 		"productIds": {
 * 			components: {
 * 				"0": {component: str, qty/grams/liters: str},
 * 			}
 * 		}
 * }
 *
 * nameIds {
 * 		"productShorthands": [
 * 			["productId", "productSize"],
 * 			[123, "XL"],
 * 		]
 * }
 *
 */

document.addEventListener("alpine:init", () => {
    const store = {
        shops: [
            {
                name: "Berlin",
                url: "refueat.de",
                address: "Friedhofsweg 1, 12529 Schönefeld",
                google_direction_link_address: "Friedhofsweg+1,+12529+Schönefeld",
            },
            {
                name: "Frankfurt",
                url: "frankfurt.refueat.de",
                address: "Schulstr. 12, 67256 Weisenheim am Sand",
                google_direction_link_address: "Schulstraße+12,+67256+Weisenheim+am+Berg"
            },
            {
                name: "NRW",
                url: "nrw.refueat.de",
                address: "Arndtstraße 20, 58097 Hagen",
                google_direction_link_address: "Arndtstraße+20,+58097+Hagen",
            },
            {
                name: "DEV",
                url: "dev.refueat.de",
                address: "Friedhofsweg 1, 12529 Schönefeld",
                google_direction_link_address: "Friedhofsweg+1,+12529+Schönefeld",
            },
        ],
        curShop: Alpine.$persist({
            name: "Berlin",
            url: "refueat.de",
        }),
        auth: {
            username: "",
            password: "",
            jwts: Alpine.$persist({}),
            showLoginForm: false,
            loading: false,
        },
        doAuth: async function (evt) {
            evt.preventDefault();
            const store = Alpine.store("refueat");

            if (!store.auth.username?.length || !store.auth.password?.length)
                return;

            // get jwt
            const url =
                "https://" + store.curShop.url + "/wp-json/jwt-auth/v1/token";

            try {
                store.auth.loading = true;
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: store.auth.username,
                        password: store.auth.password,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                store.auth.jwts[store.curShop.url] = result.token;
                store.auth.loading = false;
                checkAuth();
            } catch (error) {
                store.auth.loading = false;
                console.error("Error:", error);
            }
        },
        lastModified: "",
        previousLastModified: Alpine.$persist(""),
        showTable: false,
        loading: false,
        curDate: "",
        currentlyProcessingOrdersFor: {},
        orderData: Alpine.$persist({}), // object that contains orders infos like id, time referenced by order_date
        fetchedVariations: [], // array caching all fetched product variations in a session

        savedSpecialProducts: [], // array keeping track of each spezialprodukt item_id
        specialProductIdCounter: Alpine.$persist(0), // have a way to identify each separate Spezialprodukt

        costlyProducts: Alpine.$persist({}),
        hotOrDrinkIds: Alpine.$persist({}),
        personnelOrDishIds: Alpine.$persist({}),
        componentPosts: Alpine.$persist({}), // array that contains the bestandteil posts from backend
        productCategories: Alpine.$persist({}),

        productQuantities: Alpine.$persist({}), // object that contains products and respective quantities per order and totals
        sortedProductQuantities: Alpine.$persist({}), // array that contains same information as the object above but sorted
        productQuantitiesByCategory: Alpine.$persist({}),

        productComponents: {}, // object that contains products components
        productImages: {}, // object that contains products thumbnail images
        nameIds: {}, // object that contains all relevant productIds for a shorthand defined in a _product_table
        activeComponents: "", // string to display in the tooltip
        activeImage: "", // image to display in the tooltip when needed
        clickedProductShorthand: "", // name of the product whose components are shown
        activePreviousOrders: "", // string to display in the tooltip
        clickedCustomer: "", // name of the customer whose order history is shown
        clickedCells: [],
        fullyClickedRowsTotalCells: [],
        clickedFinishedCells: [],
        fullyClickedFinishedRowsTotalCells: [],
        preparingCells: [],
        currentProcesses: [], // array keeping track of all running processes (supabase)
        fullyPreparingRowsTotalCells: [],
        finishedCells: [],
        finishedProcesses: [], // array keeping track of all finished processes (supabase)
        fullyFinishedRowsTotalCells: [],
        packedCells: [],
        packedProducts: [], // array keeping track of all fetched packed products (supabase)
        fullyPackedRowsTotalCells: [],
        clickedLocationCells: [],
        clickedLocations: [],
        routeLink: "",
        embedRouteLink: "",
        workers: [
            {
                id: "ali",
                name: "Ali",
                isActive: false,
                isPausing: false,
                isPreparing: false,
                preparingCells: [],
                preparingProductsOrdersQuantities: {},
            },
            {
                id: "aycha",
                name: "Aycha",
                isActive: false,
                isPausing: false,
                isPreparing: false,
                preparingCells: [],
                preparingProductsOrdersQuantities: {},
            },
            {
                id: "aymann",
                name: "Aymann",
                isActive: false,
                isPausing: false,
                isPreparing: false,
                preparingCells: [],
                preparingProductsOrdersQuantities: {},
            },
            {
                id: "mahmoud",
                name: "Mahmoud",
                isActive: false,
                isPausing: false,
                isPreparing: false,
                preparingCells: [],
                preparingProductsOrdersQuantities: {},
            },
            {
                id: "mazkatli",
                name: "Mazkatli",
                isActive: false,
                isPausing: false,
                isPreparing: false,
                preparingCells: [],
                preparingProductsOrdersQuantities: {},
            },
            {
                id: "omar",
                name: "Omar",
                isActive: false,
                isPausing: false,
                isPreparing: false,
                preparingCells: [],
                preparingProductsOrdersQuantities: {},
            },
            // {
            //     id: "test",
            //     name: "Test",
            //     isActive: false,
            //     isPausing: false,
            //     isPreparing: false,
            //     preparingCells: [],
            //     preparingProductsOrdersQuantities: {},
            // },
        ],
        activeWorker: null,
        preparingWorkers: [],
        interval: "",
        time: "",
        initTimer() {
            this.interval = setInterval(() => {
                this.time = new Date();
            }, 1000);
        },
        daysOfTheWeek: [],
        // killTimer() {
        // 	clearInterval(this.interval)
        // },
        getElapsedPreparationTime: function (workerId) {
            // find the process by worker id
            let process = this.currentProcesses.find(
                (p) => p.employee == workerId
            );
            if (process && this.time) {
                const start = new Date(process.created_at);
                const now = this.time;

                // subtract the breaks from elapsed time
                let breaksMs = 0;
                if (process.breaks) {
                    for (const theBreak of process.breaks) {
                        let ended_at = theBreak.ended_at;
                        if (!ended_at) ended_at = now;
                        breaksMs += Math.abs(
                            new Date(ended_at) - new Date(theBreak.started_at)
                        );
                    }
                }

                let ms = Math.floor(now - start);
                ms -= breaksMs;
                if (ms < 0) ms = 0; // if condition for first tick

                let timeElapsed = new Date();
                timeElapsed.setTime(ms);

                return timeElapsed.getUTCHours() == 0
                    ? timeElapsed.getMinutes().toString().padStart(2, "0") +
                          ":" +
                          timeElapsed.getSeconds().toString().padStart(2, "0")
                    : timeElapsed.getUTCHours().toString().padStart(2, "0") +
                          ":" +
                          timeElapsed.getMinutes().toString().padStart(2, "0") +
                          ":" +
                          timeElapsed.getSeconds().toString().padStart(2, "0");
            }
        },
        showFinishDialog: false,
        showRevertDialog: false,
        showPackageRevertDialog: false,
        showBoxDialog: false,
        showIFrame: false,
        cellToRevert: "",
        amountOfBoxes: 24,
        boxesLoading: false,
        currentlyClickedBoxes: [],
        orderOfBoxEdit: {},
    };
    const lastModSplit = document.lastModified.split(" ");
    const d = lastModSplit[0].split("/");
    const t = lastModSplit[1].split(":");

    store.lastModified = `${d[1]}.${d[0]}.${d[2]} ${t[0]}:${t[1]}`;

    if (store.lastModified != store.previousLastModified) {
        // we clear localstorage, as we are dealing with a new version that could include structure changes.
        if (location.hostname != "localhost") {
            for (const item of Object.keys(localStorage)) {
                if (item == "_x_auth.jwts") continue;
                if (item == "_x_curShop") continue;
                localStorage.removeItem(item);
            }
        }
        store.previousLastModified = store.lastModified;
    }

    Alpine.store("refueat", store);

    const urlParams = new URLSearchParams(window.location.search);
    const urlDate = urlParams.get("date");
    let date;
    if (urlDate) {
        // use passed param as date
        date = new Date(urlDate);
    } else {
        // default date to today
        date = new Date();
    }

    let isoDate = date.toLocaleDateString("sv"); // sweden locale uses iso format
    document.getElementById("date").value = isoDate;
    // document.getElementById("date").value = "2023-03-10";

    // data cleansing
    clearOldData();

    // init other stuff and listeners
    supabaseFetch();
    supabaseRealtimeInit();
    // processOrders(false);
    // wcRealtimeInit() // call this after first order fetch

    document.querySelector("#date").addEventListener("change", function () {
        if (!Alpine.store("refueat").loading) {
            supabaseFetch();
            Alpine.store("refueat").clickedLocationCells = []; // if we change the date, we reset the locations link
            Alpine.store("refueat").clickedLocations = []; // if we change the date, we reset the locations link
            processOrders(false);
        }
    });

    refreshAuth();
    Alpine.effect(() => {
        const store = Alpine.store("refueat");
        const curShop = store.curShop;
        console.log(curShop);
        if (!!curShop.name) {
            checkAuth();
        }
    });
});

function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

function checkAuth() {
    console.log("checkAuth");
    const store = Alpine.store("refueat");
    const curJwt = store.auth.jwts[store.curShop.url];
    if (!!curJwt) {
        const authData = parseJwt(curJwt);
        const nowTimestamp = Math.floor(new Date().getTime() / 1000);
        if (nowTimestamp <= authData.exp) {
            console.log("valid auth");
            store.auth.showLoginForm = false;
            store.showLocationSelection = false;
            setTimeout(() => {
                processOrders(false);
            }, 100);
            return true;
        } else {
            store.auth.showLoginForm = true;
            return false;
        }
    } else {
        store.auth.showLoginForm = true;
        return false;
    }
}

async function refreshAuth() {
    const store = Alpine.store("refueat");
    try {
        // for (const shop of store.shops) {
        if (!store.auth.jwts[store.curShop.url]) {
            return;
        }

        const url =
            "https://" +
            store.curShop.url +
            "/wp-json/jwt-auth/v1/token/refresh";

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + store.auth.jwts[store.curShop.url],
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        store.auth.jwts[store.curShop.url] = result.token;
        // }
    } catch (error) {
        store.auth.loading = false;
        console.error("Error:", error);
        store.showLocationSelection = true;
    }
}

function clearOldData() {
    const store = Alpine.store("refueat");

    let todayISO = new Date().toLocaleDateString("sv");

    for (const shop in store.orderData) {
        for (const date in store.orderData[shop]) {
            if (date < todayISO) {
                delete store.orderData[shop][date];
                delete store.productQuantities[shop]?.[date];
                delete store.sortedProductQuantities[shop]?.[date];
            }
        }
    }
}
